<footer class="footer">
  <div class="container">
    <div class="row row-grid align-items-center mb-5">
      <div class="text-center col btn-wrapper">
        <button target="_blank" href="https://www.youtube.com/channel/UCyZ7Gbv5Lnovuly0I7To-QQ/featured" rel="nofollow" class="btn-icon-only rounded-circle btn btn-instagram" data-toggle="tooltip" data-original-title="Like us">
          <span class="btn-inner--icon"><i class="fab fa-instagram"></i></span>
        </button>
        <button target="_blank" href="https://www.youtube.com/channel/UCyZ7Gbv5Lnovuly0I7To-QQ/featured" rel="nofollow" class="btn-icon-only rounded-circle btn btn-youtube" data-toggle="tooltip" data-original-title="Like us">
          <span class="btn-inner--icon"><i class="fab fa-youtube"></i></span>
        </button>
        <button target="_blank" (click)="contactAtTop()" rel="nofollow" class="btn btn-icon-only btn-github rounded-circle" data-toggle="tooltip" data-original-title="Star on Github">
          <span class="btn-inner--icon"><i class="fa fa-envelope"></i></span>
        </button>
      </div>
    </div>
    <hr>
    <div class="row align-items-center justify-content-md-between">
      <div class="col-md-6">
        <div class="copyright">
          <i class="fa fa-mug-hot"></i>
          2021 <a href="#" target="_self">Stay Safe</a>.
        </div>
      </div>
      <div class="col-md-6">
        <ul class="nav nav-footer justify-content-end">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/']" (click)="backToTOp()" >Stay Safe</a>
          </li>
<!--          <li class="nav-item">-->
<!--            <a href="" class="nav-link" target="_blank">Sobre Nós</a>-->
<!--          </li>-->
          <li class="nav-item">
            <a class="nav-link" (click)="contactAtTop()">Contato</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
