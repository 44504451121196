import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment.prod';
import {Contact} from '../model/contact';

@Injectable({
  providedIn: 'root'
})
export class SendMailService {

  constructor(private http: HttpClient) {
  }

  sendContactMail(
    name,
    comp,
    mailAddr,
    subJect,
    mesSage
  ): boolean {
    this.http.post(environment.MAIL_API_URL + 'email/send-email/mailObj',
      {
        fullName: name,
        company: comp,
        emailAddress: mailAddr,
        subject: subJect,
        message: mesSage
      })
      .subscribe(
        (val) => {
          console.log('contact mail sent successfully',
            val);
        },
        response => {
          console.log('contact mail error', response);
          return false;
        },
        () => {
          console.log('The POST observable is now completed.');
        });
    return true;
  }
}
