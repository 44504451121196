import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-contact-form-confirm',
  templateUrl: './contact-form-confirm.component.html',
  styleUrls: ['./contact-form-confirm.component.css']
})
export class ContactFormConfirmComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ContactFormConfirmComponent>) {
  }

  ngOnInit(): void {
  }

  okayButton(): void {
    this.dialog.closeAll();
  }

}
