import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-form',
  templateUrl: './cancel-form.component.html',
  styleUrls: ['./cancel-form.component.css']
})
export class CancelFormComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CancelFormComponent>) {
  }

  ngOnInit(): void {
  }

  clearForm(): void {
    this.dialog.closeAll();
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
