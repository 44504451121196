<!-- Navbar -->
<nav id="navbar-main" class="navbar navbar-main navbar-expand-lg navbar-transparent navbar-light py-2">
  <div class="container">
    <a class="navbar-brand mr-lg-5" href="index.html">
      <img src="assets/img/brand/white.png">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar_global"
            aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse" id="navbar_global">
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a href="index.html">
              <img src="assets/img/brand/blue.png">
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbar_global"
                    aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <li class="nav-item dropdown">
          <a href="#" class="nav-link" data-toggle="dropdown" role="button">
            <i class="ni ni-ui-04 d-lg-none"></i>
            <span class="nav-link-inner--text">Serviços</span>
          </a>
          <div class="dropdown-menu dropdown-menu-xl">
            <div class="dropdown-menu-inner">
              <!--              <a [routerLink]="['/products/voip']" routerLinkActive="router-link-active"  class="media d-flex align-items-center">-->
              <!--                <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">-->
              <!--                  <i class="fa fa-phone-volume"></i>-->
              <!--                </div>-->
              <!--                <div class="media-body ml-3">-->
              <!--                  <h6 class="heading text-primary mb-md-1">Telefonia VOIP</h6>-->
              <!--                  <p class="description d-none d-md-inline-block mb-0">Saiba mais sobre essa tecnologia e como ela pode transformar seu negócio.</p>-->
              <!--                </div>-->
              <!--              </a>-->

              <a [routerLink]="['/products/development']" class="media d-flex align-items-center">
                <div class="icon icon-shape bg-gradient-purple rounded-circle text-white">
                  <i class="fa fa-file-code"></i>
                </div>
                <div class="media-body ml-3">
                  <h5 class="heading text-primary mb-md-1">Desenvolvimento de Software</h5>
                  <p class="description d-none d-md-inline-block mb-0">Saiba como você pode ter um sistema para agilizar
                    os processos de sua empresa.</p>
                </div>
              </a>
              <a [routerLink]="['/products/security']" class="media d-flex align-items-center">
                <div class="icon icon-shape bg-gradient-red rounded-circle text-white">
                  <i class="fa fa-key"></i>
                </div>
                <div class="media-body ml-3">
                  <h6 class="heading text-primary mb-md-1">Segurança da Informação</h6>
                  <p class="description d-none d-md-inline-block mb-0">Seu negócio seguro contra ameaças.</p>
                </div>
              </a>
              <a [routerLink]="['/products/consultancy']" class="media d-flex align-items-center">
                <div class="icon icon-shape bg-gradient-success rounded-circle text-white">
                  <i class="fa fa-tasks"></i>
                </div>
                <div class="media-body ml-3">
                  <h6 class="heading text-primary mb-md-1">Consultoria</h6>
                  <p class="description d-none d-md-inline-block mb-0">Organize o seu negócio.</p>
                </div>
              </a>
<!--              <a [routerLink]="['/products/backup']" class="media d-flex align-items-center">-->
<!--                <div class="icon icon-shape bg-gradient-success rounded-circle text-white">-->
<!--                  <i class="fa fa-shield"></i>-->
<!--                </div>-->
<!--                <div class="media-body ml-3">-->
<!--                  <h6 class="heading text-primary mb-md-1">Backup</h6>-->
<!--                  <p class="description d-none d-md-inline-block mb-0">Veja como nosso backup automatizado pode te-->
<!--                    deixar mais tranquilo.</p>-->
<!--                </div>-->
<!--              </a>-->
<!--              <a [routerLink]="['/products/cloud-computing']" class="media d-flex align-items-center">-->
<!--                <div class="icon icon-shape bg-gradient-warning rounded-circle text-white">-->
<!--                  <i class="fa fa-cloud"></i>-->
<!--                </div>-->
<!--                <div class="media-body ml-3">-->
<!--                  <h5 class="heading text-warning mb-md-1">Cloud Computing</h5>-->
<!--                  <p class="description d-none d-md-inline-block mb-0">Entenda o que é nuvem e como seu negócio pode se-->
<!--                    ganhar com isso.</p>-->
<!--                </div>-->
<!--              </a>-->
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a href="#" class="nav-link" data-toggle="dropdown" role="button">
            <i class="ni ni-ui-04 d-lg-none"></i>
            <span class="nav-link-inner--text">Legal</span>
          </a>
          <div class="dropdown-menu dropdown-menu-xl">
            <div class="dropdown-menu-inner">
              <a class="media d-flex align-items-center" (click)="openTermsConditions()">
                <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                  <i class="fa fa-hand-paper-o"></i>
                </div>
                <div class="media-body ml-3">
                  <h6 class="heading text-primary mb-md-1">Termos e condições</h6>
                  <p class="description d-none d-md-inline-block mb-0">Confira nossa forma de trabalhar</p>
                </div>
              </a>
              <a class="media d-flex align-items-center" (click)="openPrivacy()">
                <div class="icon icon-shape bg-gradient-success rounded-circle text-white">
                  <i class="fa fa-user-secret"></i>
                </div>
                <div class="media-body ml-3">
                  <h6 class="heading text-primary mb-md-1">Privacidade</h6>
                  <p class="description d-none d-md-inline-block mb-0">Saiba como cuidamos dos seus dados.</p>
                </div>
              </a>
            </div>
          </div>
        </li>
        <!--        <li class="nav-item dropdown">-->
        <!--          <a href="#" class="nav-link" data-toggle="dropdown" role="button">-->
        <!--            <i class="ni ni-collection d-lg-none"></i>-->
        <!--            <span class="nav-link-inner&#45;&#45;text">Examples</span>-->
        <!--          </a>-->
        <!--          <div class="dropdown-menu">-->
        <!--            <a href="#" class="dropdown-item">Landing</a>-->
        <!--            <a href="#" class="dropdown-item">Profile</a>-->
        <!--            <a href="#" class="dropdown-item">Login</a>-->
        <!--            <a href="#" class="dropdown-item">Register</a>-->
        <!--          </div>-->
        <!--        </li>-->
      </ul>
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <!-- <li class="nav-item">
          <a class="nav-link nav-link-icon" href="https://www.facebook.com/" target="_blank" data-toggle="tooltip" title="Like us on Facebook">
            <i class="fa fa-facebook-square"></i>
            <span class="nav-link-inner--text d-lg-none">Facebook</span>
          </a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link nav-link-icon" href="https://www.instagram.com/" target="_blank" data-toggle="tooltip"
             title="Follow us on Instagram">
            <i class="fa fa-instagram"></i>
            <span class="nav-link-inner--text d-lg-none">Instagram</span>
          </a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link nav-link-icon" href="https://twitter.com/" target="_blank" data-toggle="tooltip" title="Follow us on Twitter">
            <i class="fa fa-twitter-square"></i>
            <span class="nav-link-inner--text d-lg-none">Twitter</span>
          </a>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link nav-link-icon" href="https://github.com/" target="_blank" data-toggle="tooltip" title="Star us on Github">
            <i class="fa fa-github"></i>
            <span class="nav-link-inner&#45;&#45;text d-lg-none">Github</span>
          </a>
        </li> -->
        <li class="nav-item">
          <button class="btn btn-neutral" (click)="openDialog()">
            <span class="nav-link-inner--text">Contato</span>
          </button>
        </li>
        <!-- <li class="nav-item d-none d-lg-block">
          <a routerLink="/client" target="_blank" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon">
                <i class="fa fa-user"></i>
              </span>
            <span class="nav-link-inner--text"> Área do Cliente</span>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</nav>
