import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SendMailService} from '../../../@core/api/send-mail.service';
import {CancelFormComponent} from '../cancel-contact-form/cancel-form.component';
import {ContactFormConfirmComponent} from '../contact-form-confirm/contact-form-confirm.component';
import {MailChimpService} from '../../../@core/api/mail-chimp.service';
import {MailChimpSubscribeService} from '../../../@core/api/subscribe-mailchimp';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

  public breakpoint: number; // Breakpoint observer code
  public fullname = ``;
  public company = ``;
  public email = ``;
  public subject = ``;
  public message = ``;

  returnMessage: string;
  result: string;

  public contactForm: FormGroup;
  wasFormChanged = false;
  sent = false;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ContactFormComponent>, // Closing dialog window
    public sendMailService: SendMailService,
    private mailChimpService: MailChimpService,
    public mailchimpSubscribeService: MailChimpSubscribeService
  ) {
  }

  public ngOnInit(): void {
    this.contactForm = this.fb.group({
      IdProof: null,
      fullname: [this.fullname, [Validators.required, Validators.pattern('[a-zA-Z]+([a-zA-Z ]+)*')]],
      company: [this.company, [Validators.required, Validators.pattern('[a-zA-Z]+([a-zA-Z ]+)*')]],
      email: [this.email, [Validators.required, Validators.email]],
      subject: [this.subject],
      message: [this.message],
    });
    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onAddCus(): void {
    this.markAsDirty(this.contactForm);
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged(): void {
    this.wasFormChanged = true;
  }

  public cancel(): void { // To cancel the dialog window
    console.log(this.wasFormChanged);
    if (this.contactForm.dirty) {
      const dialogRef = this.dialog.open(CancelFormComponent, {
        width: '340px',
      });
    } else {
      this.dialog.closeAll();
    }
  }

  public isMessageSent(): boolean {
    return this.sent;
  }

  public submitContact() {
    this.mailChimpService.submit(
      this.contactForm.get('fullname').value,
      this.contactForm.get('email').value
    ).subscribe(res => {
      this.returnMessage = res.msg;
      this.result = res.result;
    }, err => {
      this.message = err.msg;
      this.result = err.result;
    });
  }

  public sendContact(): void {

    this.mailchimpSubscribeService.submit(
      this.contactForm.get('fullname').value,
      this.contactForm.get('email').value,
    ).subscribe(res => {
      if (res.result && res.result !== 'error' ) {
        this.sent = true;
        console.log(res);
      } else {
        console.log('ERROR');
      }
    });
    // this.sent = this.sendMailService.sendContactMail(
    //   this.contactForm.get('fullname').value,
    //   this.contactForm.get('company').value,
    //   `contato@staysafe.com.br`,
    //   `Contact from: ` + this.contactForm.get('email').value,
    //   this.contactForm.get('message').value,
    // );

    const dialogRef = this.dialog.open(ContactFormConfirmComponent, {
      width: '240px',
    });
  }


}
