import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Injectable({
  providedIn: 'root'
})
export class MailChimpSubscribeService {
  submitted = false;
  mailchimpEndpoint = 'https://staysafe.us1.list-manage.com/subscribe/post-json?u=fdcf25fb7e6e53145bf4c62ec&amp;id=378d7a1526&';
  error = '';

  constructor(private http: HttpClient) {
  }

  submit(name, email): Observable<MailChimpResponse> {
    this.error = '';
    const params = new HttpParams()
      .set('NAME', name)
      .set('EMAIL', email)
      .set('b_fdcf25fb7e6e53145bf4c62ec_378d7a1526', '');  // hidden input name

    const mailChimpUrl = this.mailchimpEndpoint + params.toString();

    return this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c');
  }
}
