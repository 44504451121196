import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {async} from 'rxjs';
import {ContactFormComponent} from '../contact-form/contact-form.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  backToTOp(): void {
    (function smoothscroll(): void {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 20));
      }
    })();
  }

  async contactAtTop(): Promise<void> {
    this.backToTOp();
    await this.sleep(2000);
    const dialogRef = this.dialog.open(ContactFormComponent, {
      width: '860px', disableClose: true
    });
  }
}
