import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ContactFormComponent} from '../contact-form/contact-form.component';
import {PrivacyComponent} from '../../../pages/legal/privacy/privacy.component';
import {TermsConditionsComponent} from '../../../pages/legal/terms-conditions/terms-conditions.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ContactFormComponent, {
      width: '860px', disableClose: true
    });
  }

  openPrivacy(): void {
    const dialogRef = this.dialog.open(PrivacyComponent, {
      width: '860px', disableClose: false
    });
  }

  openTermsConditions(): void {
    const dialogRef = this.dialog.open(TermsConditionsComponent, {
      width: '860px', disableClose: false
    });
  }
}
