import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {FooterComponent} from './@theme/components/footer/footer.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {HeaderModule} from './@theme/components/header/header.module';
import {HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {SendMailService} from './@core/api/send-mail.service';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faCloud, faHeadset, faPhoneVolume, faShieldAlt} from '@fortawesome/free-solid-svg-icons';
import { CancelFormComponent } from './@theme/components/cancel-contact-form/cancel-form.component';
import {MatDialogModule} from '@angular/material/dialog';
import { ContactFormConfirmComponent } from './@theme/components/contact-form-confirm/contact-form-confirm.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    CancelFormComponent,
    ContactFormConfirmComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HeaderModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FontAwesomeModule,
    MatDialogModule
  ],
  providers: [
    SendMailService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(
      faHeadset,
      faCloud,
      faShieldAlt,
      faPhoneVolume,
      );
  }
}
