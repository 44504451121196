<div>
  <div class="add-title">
    <h2 class="primary">Contato</h2>
  </div>
  <div>
    <!--      <form [formGroup]="addCusForm" (change)="formChanged()" (submit)="sendMessage()">-->
    <form [formGroup]="contactForm" (change)="formChanged()">
      <mat-grid-list cols="1" [cols]="breakpoint" rowHeight="85px" (window:resize)="onResize($event)">
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field>
              <input matInput placeholder="Nome" value={{fullname}} formControlName="fullname" required>
              <mat-error *ngIf="contactForm.controls.fullname.hasError('required')">
                Insira seu nome
              </mat-error>
              <mat-error *ngIf="contactForm.controls.fullname.hasError('pattern')">
                Insira um nome válido
              </mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="example-full-width">-->
<!--              <input matInput placeholder="Empresa" value={{company}} formControlName="company" required>-->
<!--              <mat-error *ngIf="contactForm.controls.company.hasError('required')">-->
<!--                Insira o nome da sua empresa-->
<!--              </mat-error>-->
<!--              <mat-error *ngIf="contactForm.controls.company.hasError('pattern')">-->
<!--                Insira um nome válido-->
<!--              </mat-error>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->

        <!-- <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field>
              <input matInput [matDatepicker]="picker1" placeholder="Date of birth">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
        </mat-grid-tile> -->

        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="E-mail" formControlName="email" required>
              <mat-error *ngIf="contactForm.controls.email.hasError('required')">
                Insira seu e-mail
              </mat-error>
              <mat-error *ngIf="contactForm.controls.email.hasError('email')">
                Por favor insira um e-mail válido
              </mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>

<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-radio-group class="subject">-->
<!--              <div style="color:grey;">Assunto</div>-->
<!--              <mat-radio-button color="primary" style="padding:4px 4px 0px 0px;" value="1">Voip</mat-radio-button>-->
<!--              <mat-radio-button class="example-radio-button" *ngFor="let season of seasons" [value]="season">-->
<!--              {{season}}-->
<!--              </mat-radio-button>-->
<!--              <mat-radio-button color="primary" style="padding:4px 4px 0px 0px;" value="1">Backup</mat-radio-button>-->
<!--              <mat-radio-button color="primary" style="padding:4px 0px 0px 24px;" value="2">Cloud</mat-radio-button>-->
<!--              <mat-radio-button color="primary" style="padding:4px 0px 0px 24px;" value="3">Desenvolvimento</mat-radio-button>-->
<!--            </mat-radio-group>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->

<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field>-->
<!--              <textarea matInput placeholder="Mais detalhes" formControlName="message"></textarea>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->

      </mat-grid-list>

      <div class="btn-sec">
        <button mat-raised-button type="submit" color="primary" class="Update-btn" (click)="sendContact()">Enviar</button>
        <button mat-raised-button type="button" class="Discard-btn" (click)="cancel()">Cancelar</button>
      </div>

      <mat-progress-bar mode="indeterminate" *ngIf='isMessageSent()'></mat-progress-bar>

    </form>
  </div>
</div>
